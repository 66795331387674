<template>
  <div>
    <b-modal
      title="Adicionar novo arquivo"
      id="bill-attachments-modal"
      hide-footer
      centered
      size="lg"
    >

      <div class="modal-body">
        <div class="file-name__input" v-if="editMode">
          <label for="file-name">Nome do arquivo</label>
          <input
            type="text"
            id="file-name"
            v-model="fileName"
            :class="{ 'error': error }"
          />

          <b-button
            variant="primary"
            @click="onSaveFileName"
          >
            Salvar
          </b-button>

        </div>
      <div class="file-atach md-content">
        <div
          class="file-container"
          :class="{ 'without-file': !file }"
          @dragover.prevent
          @drop.prevent
        >
          <div @drop="dragFile" class="drag-area">
            <div v-if="!file">
              <p>Arraste os arquivos aqui</p>

              <div class="separator"><LineIcon /> ou <LineIcon /></div>

              <label for="add-file-input" class="add-btn"

                >Anexar arquivos</label
              >
              <input
                id="add-file-input"
                type="file"
                accept=".jpg, .png, .pdf, .jpeg, .jps, .tiff"
                @change="addFile"
              />
            </div>
            <div class="file" v-else>
              <div>
                <FilesIcon />
                {{ file?.name || '' }}
              </div>
              <div class="file-actions">
                <DownloadIcon fill="#7F91D2" class="icon download" 
                @click="onDownloadFile"/>
                <EditIcon class="icon"
                @click="onEditFile" />
                <DeleteIcon
                  class="icon"
                  @click="onDeleteFile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="modal-footer">
        <b-button variant="primary" @click="closeModal">Voltar</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'BillAttachmentsModal',
  components: {
    LineIcon: () => import('@/assets/icons/line.svg'),
    DeleteIcon: () => import('@/assets/icons/delete.svg'),
    EditIcon: () => import('@/assets/icons/edit.svg'),
    FilesIcon: () => import('@/assets/icons/files.svg'),
    DownloadIcon: () => import('@/assets/icons/attach.svg')
  },
  props: {
    bill: Object,
    fileProp: Object
  },
  mounted() {
    if (this.fileProp) {
      this.file = this.fileProp
    }
  },
  data() {
    return {
      file: null,
      fileName: '',
      error: false,
      editMode: false
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('bill-attachments-modal')
    },
    onDeleteFile() {
      this.error = false
      this.file = null
    },
    onEditFile() {
      this.error = false
      this.editMode = true
      this.fileName = this.file.name
    },
    onDownloadFile(event) {
      event.preventDefault()

      const url = URL.createObjectURL(this.file)
      const a = document.createElement('a')
      a.href = url
      a.download = this.file.name
      document.body.appendChild(a)
      a.click() 
      document.body.removeChild(a)

            
    },
    addFile(e) {
      this.error = false
      this.file = e.target.files[0]
    },
    dragFile(e) {
      this.error = false
      const file = e.dataTransfer.files[0]
      const fileType = file.type
      this.fileExtension = fileType.split('/')[1]
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : ''
      if (
        ![
          'pdf',
          'jpg',
          'jpeg',
          'png',
          'jps',
          'application/pdf',
          'image/jpg',
          'image/jpeg',
          'image/png',
          'tiff'
        ].includes(fileType || nameExt)
      ) {
        return this.$toast.error('Formato de arquivo não suportado!')
      }
      this.file = e.dataTransfer.files[0]
    },
    onSaveFileName() {
      if (!this.fileName) {
        this.error = true
        return
      }
      this.file = new File([this.file], this.fileName, { type: this.file.type })
      this.editMode = false
    }
  },
  watch: {
    file: {
      handler() {
        if (!this.error) {
          this.$emit('saveFile', this.file)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#bill-attachments-modal {
  .file-atach {
    .file-container {
      height: 100px;
      background: transparent;

      &.without-file {
        height: 160px;
        border: 1px dashed var(--blue-500);
        box-sizing: border-box;
        border-radius: 8px;
        background: var(--neutral-100);
      }

      .drag-area {
        width: 100%;
        height: 100%;
        padding: 20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        color: var(--type-active);
        text-align: center;

        .separator {
          margin: 12px 0px;
        }

        .add-btn {
          background: var(--blue-700);
          border-radius: 8px;
          color: var(--neutral-000);
          padding: 8px 15px;
          cursor: pointer;

          font-family: Nunito Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }

        #add-file-input {
          display: none;
        }

        .file {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: space-between;
          align-content: center;

          .file-actions {
            display: flex;
            justify-content: flex-end;
            
            .icon {
            width: 24px;
            height: 24px;
            margin-right: 3px;
            cursor: pointer;

            &.download {
              fill: #7F91D2;
              color: #7F91D2;
              
            }
          }
        }

        }
      }
    }
  }

  .model-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      display: flex;
    }
  }
}
</style>
